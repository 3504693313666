export default {
  LOGIN_STATUS: 'loginStatus',
  LOGIN: 'login',
  LOGIN_USER: 'loginUser',
  LOGIN_CODE: 'loginUserCode',
  LOGIN_ACCOUNT: 'loginAccount',
  LOGIN_RESPONSE: 'loginResponse',
  QUEUE_EVENT: 'QueueEvent',
  QUEUES_UPDATED: 'QueuesUpdated',
  DIALERS_UPDATED: 'DialersUpdated',
  EXTENSION_EVENT: 'ExtensionEvent',
  EXTENSION_UPDATED: 'ExtensionsUpdated',
  ALL_EXTENSION_STATUS: 'AllExtensionsStatus',
  CONNECT: 'connect',
  CONNECT_ERROR: 'connect_error',
  CONNECT_TIMEOUT: 'connect_timeout',
  DISCONNECT: 'disconnect',
  PONG: 'pong',
  RECONNECT: 'reconnect',
  RECONNECT_ATTEMPT: 'reconnect_attempt',
  RESYNC: 'resync',
  RECONNECTING: 'reconnecting',
  RECONNECT_ERROR: 'reconnect_error',
  RECONNECT_FAILED: 'reconnect_failed',
  KEEP_ALIVE: 'keepalive',
  KEEP_ALIVE_RESPONSE: 'keepaliveResponse',
  CLOSE: 'closeme',
  ERROR: 'error',
}
